import { Typography, TypographyType } from 'components/DataDisplay/Typography/Typography'
import { Link } from 'components/Link/Link'
import { useTranslation } from 'hooks/useTranslation'
import { FC } from 'react'
import { Routes } from 'services/router/Routes'

import styles from './Footer.module.scss'

export type FooterProps = {
  className?: string
}
export const Footer: FC<FooterProps> = ({ className }) => {
  const { t } = useTranslation()
  return (
    <footer className={`${styles.footer} ${className && className}`}>
      <div className={styles.container}>
        <div className={styles.footerLogoContainer}>
          <Link route={Routes.landing()} newTarget>
            <img src="/images/logo-white.svg" alt="Libeen" className={styles.footerLogo} height={40} width={174} />
          </Link>
          <Typography typographyType={TypographyType.PARAGRAPH} className={styles.footerRights}>
            Libeen © {new Date().getFullYear()} {t('footer:copyright')}
          </Typography>
        </div>
        <div className={styles.footerLinksWrapper}>
          <nav className={styles.footerLinksContainer}>
            <Typography typographyType={TypographyType.PARAGRAPH} className={styles.footerTitles}>
              {t('footer:legal-title')}
            </Typography>
            <Link route={Routes.privacy()} className={styles.footerLink} newTarget>
              <Typography typographyType={TypographyType.PARAGRAPH}>{t('footer:privacy')}</Typography>
            </Link>
            <Link route={Routes.legal()} className={styles.footerLink} newTarget>
              <Typography typographyType={TypographyType.PARAGRAPH}>{t('footer:legal')}</Typography>
            </Link>
            <Link route={Routes.cookies()} className={styles.footerLink} newTarget>
              <Typography typographyType={TypographyType.PARAGRAPH}>{t('footer:cookies')}</Typography>
            </Link>
          </nav>
          <nav className={styles.footerLinksContainer}>
            <Typography typographyType={TypographyType.PARAGRAPH} className={styles.footerTitles}>
              Libeen
            </Typography>
            <Link route={Routes.howItWorks()} className={styles.footerLink}>
              <Typography typographyType={TypographyType.PARAGRAPH}>{t('footer:how-it-works')}</Typography>
            </Link>
            <Link route={Routes.reviews()} className={styles.footerLink}>
              <Typography typographyType={TypographyType.PARAGRAPH}>{t('footer:reviews')}</Typography>
            </Link>
            <Link route={Routes.about()} className={styles.footerLink}>
              <Typography typographyType={TypographyType.PARAGRAPH}>{t('footer:about-us')}</Typography>
            </Link>
            <Link route={Routes.blog()} className={styles.footerLink} newTarget>
              <Typography typographyType={TypographyType.PARAGRAPH}>{t('footer:blog')}</Typography>
            </Link>
            <Link route={Routes.faq()} className={styles.footerLink}>
              <Typography typographyType={TypographyType.PARAGRAPH}>{t('footer:faq')}</Typography>
            </Link>
          </nav>
          <nav className={styles.footerLinksContainer}>
            <Typography typographyType={TypographyType.PARAGRAPH} className={styles.footerTitles}>
              {t('footer:follow-us')}
            </Typography>
            <Link route={Routes.facebook()} className={styles.footerLink} newTarget>
              <Typography typographyType={TypographyType.PARAGRAPH}>Facebook</Typography>
            </Link>
            <Link route={Routes.instagram()} className={styles.footerLink} newTarget>
              <Typography typographyType={TypographyType.PARAGRAPH}>Instagram</Typography>
            </Link>
            <Link route={Routes.linkedin()} className={styles.footerLink} newTarget>
              <Typography typographyType={TypographyType.PARAGRAPH}>LinkedIn</Typography>
            </Link>
            <Link route={Routes.instagram()} className={styles.footerLink} newTarget>
              <Typography typographyType={TypographyType.PARAGRAPH}>Youtube</Typography>
            </Link>
            <Link route={Routes.tiktok()} className={styles.footerLink} newTarget>
              <Typography typographyType={TypographyType.PARAGRAPH}>Tiktok</Typography>
            </Link>
          </nav>
        </div>
      </div>
    </footer>
  )
}
