import React, { FC, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'hooks/useTranslation'
import { useWindowScroll } from 'react-use'
import { Icon, IconName } from 'components/DataDisplay/Icon/Icon'
import { Link } from 'components/Link/Link'
import { Routes } from 'services/router/Routes'
import { HeaderMenuMobile } from './HeaderMenuMobile'
import styles from './HeaderMobile.module.scss'

type HeaderMobile = {
  className?: string
  placeholder?: boolean
  transparent?: boolean
}

export const HeaderMobile: FC<HeaderMobile> = ({ className, placeholder = true, transparent = false }) => {
  const { y } = useWindowScroll()
  const { t } = useTranslation()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const separatedFromTop = y > 0
  const isTransparent = transparent && !separatedFromTop
  const whiteContent = isTransparent && !mobileMenuOpen
  const bottomShadow = separatedFromTop && !mobileMenuOpen

  return (
    <div className={className}>
      <header
        className={classNames(
          styles.container,
          separatedFromTop && styles.separatedFromTop,
          isTransparent && styles.transparent,
          whiteContent && styles.whiteContent,
          bottomShadow && styles.bottomShadow
        )}
      >
        <Link route={Routes.landing()} ariaLabel={t('header:index')}>
          <img className={classNames(styles.logo, styles.logoColor)} src="/images/logo.svg" alt="libeen" />
          <img className={classNames(styles.logo, styles.logoWhite)} src="/images/logo-white.svg" alt="libeen" />
        </Link>
        <button
          aria-label="Open lateral menu"
          type="button"
          className={styles.hamburgerButton}
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <Icon name={mobileMenuOpen ? IconName.CLOSE : IconName.MENU} className={styles.hamburger} />
        </button>
      </header>
      {placeholder && <div className={styles.placeholder} />}
      <HeaderMenuMobile open={mobileMenuOpen} onLinkClick={() => setMobileMenuOpen(false)} />
    </div>
  )
}
