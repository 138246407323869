import { Slider } from 'components/DataDisplay/Slider/Slider'
import { MonthlySavingsTag } from 'components/MonthlySavingsTag/MonthlySavingsTag'
import { useTranslation } from 'hooks/useTranslation'
import { FC, useState } from 'react'

import { MarketplaceProperty } from '../../model/Property'
import { formatWithSeparators } from '../../utils/formatWithSeparators'
import { Banner } from '../DataDisplay/Banner/Banner'
import { Icon, IconColor, IconName, IconType } from '../DataDisplay/Icon/Icon'
import { Typography, TypographyType } from '../DataDisplay/Typography/Typography'
import { CardContent } from '../Surfaces/Card/CardContent/CardContent'
import styles from './HousingCard.module.scss'

type HousingCardProps = MarketplaceProperty & {
  className?: string
  withoutLink?: boolean
}

export const HousingCard: FC<HousingCardProps> = ({ withoutLink = false, ...property }) => {
  const { t } = useTranslation()
  const totalPhotos = property.images.length
  const [currentSlide, setCurrentSlide] = useState(1)
  const SLIDER_OPTIONS = {
    className: styles.mainImageContainer,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    dots: false,
    variableWidth: false,
    adaptiveHeight: true,
    afterChange: (current: number) => setCurrentSlide(current + 1),
  }

  const element = (
    <div className={styles.card}>
      <div>
        <Banner type={property.status} className={styles.banner} specialOffer={property.specialOffer} />
        <div className={styles.sliderContainer}>
          {!withoutLink && (
            <div className={styles.sliderContainerPaginator}>
              <Icon name={IconName.CAMERA_2} type={IconType.LINE} color={IconColor.WHITE} size={22} />
              {`${currentSlide}/${totalPhotos}`}
            </div>
          )}
          <Slider
            options={SLIDER_OPTIONS}
            sliderSlickSlide100
            hideFirstAndLastSlidesArrows
            arrowLeftClassName={styles.sliderButtonPrev}
            arrowRightClassName={styles.sliderButtonNext}
          >
            {withoutLink ? (
              <img
                src={!property.images[0] ? '/images/image-placeholder.webp' : property.images[0]}
                alt={property.title}
                className={styles.mainImage}
              />
            ) : (
              property.images.map((image) => (
                <img key={image} src={!image ? '/images/image-placeholder.webp' : image} alt={property.title} className={styles.mainImage} />
              ))
            )}
          </Slider>
        </div>
      </div>

      <CardContent>
        <div>
          <div>
            <Typography typographyType={TypographyType.SMALL_PARAGRAPH} className={styles.zone}>
              {`${property.address.city} ${property.address.neighborhood}`}
            </Typography>
          </div>

          <div className={styles.title}>
            <Typography typographyType={TypographyType.PARAGRAPH_LEAD} className={styles.title}>
              {property.title}
            </Typography>
          </div>
        </div>

        <hr className={styles.separator} />

        <div className={styles.row}>
          <div className={styles.rowItemPrice}>
            <Typography typographyType={TypographyType.PARAGRAPH} className={styles.pricePerMonth}>
              {formatWithSeparators(property.pricePerMonth)}€<span className={styles.month}>/{t('properties:month')}</span>
            </Typography>
          </div>

          <div className={styles.rowItemPrice}>
            <Icon name={IconName.PLUS} className={styles.icon} color={IconColor.RED} />

            <Typography typographyType={TypographyType.SMALL_PARAGRAPH} className={styles.entryPayment}>
              {t('properties:entry-payment')} {formatWithSeparators(property.entryPayment)}€
            </Typography>
          </div>
        </div>

        <MonthlySavingsTag monthlySavings={property.monthlySavings} />

        <div className={styles.row}>
          <div className={styles.rowItem}>
            <Icon name={IconName.SHAPE} className={styles.icon} color={IconColor.BLUE_LIGHT} />
            <Typography typographyType={TypographyType.PARAGRAPH}>
              {property.surface}m<sup>2</sup>
            </Typography>
          </div>

          <div className={styles.rowItem}>
            <Icon name={IconName.BED} className={styles.icon} color={IconColor.BLUE_LIGHT} />
            <Typography typographyType={TypographyType.PARAGRAPH}>{property.bedroomsAmount}</Typography>
          </div>

          <div className={styles.rowItem}>
            <Icon name={IconName.SHOWER} className={styles.icon} color={IconColor.BLUE_LIGHT} />
            <Typography typographyType={TypographyType.PARAGRAPH}>{property.bathroomsAmount}</Typography>
          </div>
        </div>
      </CardContent>
    </div>
  )

  return withoutLink ? element : WithLink(element)({ url: `/properties/${property.slug}`, className: styles.cardClickable })
}

type WithLinkProps = {
  url: string
  className?: string
}

type WithLinkComponentFn = (element: JSX.Element) => (props: WithLinkProps) => JSX.Element

const WithLink: WithLinkComponentFn =
  (element) =>
  ({ url, className }) => {
    return (
      <a href={url} className={className}>
        {element}
      </a>
    )
  }
