import { useFetchPropertiesMostVisited } from 'api/queries/fetchPropertiesMostVisited'
import { ButtonLink, ButtonLinkDesign } from 'components/ButtonLink/ButtonLink'
import { Typography, TypographyType } from 'components/DataDisplay/Typography/Typography'
import { HousingCard } from 'components/HousingCard/HousingCard'
import { useTranslation } from 'hooks/useTranslation'
import { MarketplaceProperty } from 'model/Property'
import { TranslationKeys } from 'i18n/locales/translationKeys'
import { Routes } from 'services/router/Routes'
import { useMemo } from 'react'

import styles from './Housing.module.scss'

type HousingProps = {
  title?: TranslationKeys
  subtitle?: TranslationKeys
  hideButton?: boolean
  properties?: MarketplaceProperty[]
}

export const Housing = ({ title = 'home:our-houses.title', subtitle = 'home:our-houses.subtitle', hideButton, properties }: HousingProps) => {
  const { t } = useTranslation()
  const { data } = useFetchPropertiesMostVisited()

  const dataProperties = useMemo(() => {
    if (properties?.length) {
      return properties
    }

    return data?.properties || []
  }, [data, properties])

  return (
    <div className={`${styles.housingContainer} ${styles.containerFluid}`}>
      <div className={styles.container}>
        <Typography typographyType={TypographyType.H2_TITLE} className={styles.title}>
          {t(title)}
        </Typography>

        <Typography typographyType={TypographyType.PARAGRAPH} className={styles.subtitle}>
          {t(subtitle)}
        </Typography>
        <div className={styles.cardsContainer}>
          <div className={styles.cardsRow}>
            {data &&
              data.properties &&
              Array.isArray(data.properties) &&
              [...dataProperties.slice(0, 2)].map((property: MarketplaceProperty) => (
                <HousingCard withoutLink key={property.slug} {...property} />
              ))}
          </div>
          <div className={styles.cardsRow}>
            {data &&
              data.properties &&
              Array.isArray(data.properties) &&
              [...dataProperties.slice(2, 4)].map((property: MarketplaceProperty) => (
                <HousingCard withoutLink key={property.slug} {...property} />
              ))}
          </div>
        </div>
        {!hideButton && (
          <div>
            <div className={styles.housingButtonWrapper}>
              <ButtonLink href={Routes.howItWorks()} className={styles.housingButton} design={ButtonLinkDesign.BLUE}>
                {t('home:our-houses.action-button')}
              </ButtonLink>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
